import React from 'react'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTrackEvent from '../../../hooks/useTrackEvent'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import RequestButtonType from '../../../types/RequestButtonType'
import { TrackEventType } from '../../../types/TrackEventType'
import RequestStatusContainer from '../../requestStatus/RequestStatusContainer'
import SearchGridViewItemPoster from './SearchGridViewItemPoster'
import SearchGridViewItemTitle from './SearchGridViewItemTitle'

interface SearchResultsGridViewProps {
  mediaItems: MediaItemDetails[] | undefined
  isLoading: boolean
}

const rowHeight = '350px'
let rowWidth = '175px'

const skeletonItems = 24

const GridFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px;

  @media (max-width: 1180px) {
    column-gap: 60px;
  }

  @media (max-width: 844px) {
    column-gap: 60px;
  }

  @media (max-width: 820px) {
    column-gap: 40px;
  }

  @media (max-width: 768px) {
    column-gap: 30px;
  }

  @media (max-width: 667px) {
    column-gap: 60px;
  }

  @media (max-width: 390px) {
    column-gap: 25px;
  }

  @media (max-width: 375px) {
    column-gap: 20px;
  }
`

const SearchResultsGridView = ({ mediaItems, isLoading }: SearchResultsGridViewProps) => {
  const trackEvent = useTrackEvent()
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))
  if (isMobileDevice) {
    rowWidth = '155px'
  }

  if (isLoading) {
    return (
      <Grid container flexDirection="row" justifyContent="center">
        {Array.from(Array(skeletonItems)).map((item, index) => (
          <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
            <Skeleton variant="rectangular" width={rowWidth} height={rowHeight} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton variant="text" width="60%" />
            </Box>
          </Box>
        ))}
      </Grid>
    )
  }

  const handlePosterClicked = (mediaItem: MediaItemDetails) => {
    trackEvent(TrackEventType.SEARCH_RESULTS.CLICK_POSTER, {
      mediaItemId: mediaItem.id,
      mediaItemTitle: mediaItem.title
    })
  }

  return (
    <GridFlexContainer>
      {mediaItems &&
        mediaItems.map((mediaItem) => (
          <div key={mediaItem.id}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                id="search-results-grid-view-container"
                container
                style={{
                  height: rowHeight,
                  width: rowWidth,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }}
              >
                <Grid item xs onClick={() => handlePosterClicked(mediaItem)}>
                  <SearchGridViewItemPoster mediaType={mediaItem.mediaType} posterPath={mediaItem.posterPath}>
                    <RequestStatusContainer
                      buttonType={RequestButtonType.ICONBUTTON}
                      mediaItem={mediaItem}
                      buttonSx={{
                        left: isMobileDevice ? '45px' : '50px'
                      }}
                    />
                  </SearchGridViewItemPoster>
                </Grid>
                <Grid item xs style={{ padding: '0px 10px 10px 10px', width: '100%' }}>
                  <SearchGridViewItemTitle title={mediaItem.title} year={mediaItem.year} />
                </Grid>
              </Grid>
            </Box>
          </div>
        ))}
    </GridFlexContainer>
  )
}

export default SearchResultsGridView
