import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../services/apiClient'
import apiEndpoints from '../services/apiEndpoints'
import { MediaItemDetails, MediaItemDetailsAggregate } from '../types/MediaItemDetails'

export const fetchMyRequests = async (statuses: string[]): Promise<MediaItemDetails[]> => {
  const queryParams = statuses.map((status) => `status=${encodeURIComponent(status)}`).join('&')

  const response = await apiClient.get<MediaItemDetailsAggregate>(
    `${apiEndpoints.requests.mediaRequest}?${queryParams}`
  )

  return response.data.items
}

export const useMyRequests = (initialStatuses: string[]) => {
  const queryClient = useQueryClient()
  const [statuses, setStatuses] = React.useState(initialStatuses)

  const { data, isLoading, error, isSuccess } = useQuery<MediaItemDetails[], Error>(
    ['myRequests', statuses],
    () => fetchMyRequests(statuses),
    {
      refetchOnWindowFocus: false
    }
  )

  // Function to update the statuses, which triggers a re-fetch
  const getWithNewStatuses = async (newStatuses: string[], invalidateQueries = false) => {
    if (invalidateQueries) {
      await queryClient.invalidateQueries('myRequests')
    }
    setStatuses(newStatuses)
  }

  return { data, isLoading, error, isSuccess, getWithNewStatuses }
}
