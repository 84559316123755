export const TrackEventType = {
  NAV_BAR: {
    CLICK_LOGO: 'click_navBar_logo'
  },
  SEARCH_RESULTS: {
    CLICK_SEARCH: 'click_searchResults_search',
    CLICK_VIEW_CHANGED: 'click_searchResults_viewType',
    CLICK_POSTER: 'click_searchResults_poster',
    CLICK_TITLE: 'click_searchResults_title'
  },
  USER_REQUESTS: {
    CLICK_FILTER_CHANGED: 'click_userRequests_filterChanged'
  },
  CUSTOM_REQUEST: {
    CLICK_OPEN: 'click_customRequest_open',
    CLICK_CLOSE: 'click_customRequest_close',
    CLICK_SUBMIT: 'click_customRequest_submit'
  },
  PROFILE: {
    CLICK_NOTIFICATION_CHANGED: 'click_profile_notificationsChange',
    CLICK_PLEX_PROFILE_LINK: 'click_profile_profileLink'
  }
}
