import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import DOMPurify from 'dompurify'
import * as marked from 'marked'
import { ChangeLog } from '../../types/ChangeLog'
import LoadingButton from '../loading/LoadingButton'
import './changeloginfo.css'

dayjs.extend(advancedFormat)

interface ChangeLogInfoProps {
  changeLogs: ChangeLog[]
  handleClick: () => void
}

const ChangeLogContainer = styled.div`
  max-height: 75%;
  width: 40%;
  background-color: rgba(79, 79, 79, 0.98);
  overflow-y: auto;

  @media (max-width: 844px) {
    width: 90%;
  }
`

const ChangeLogHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background-color: #333333;
`

const ChangeLogHeaderTitle = styled(Typography)`
  padding: 15px;

  @media (max-width: 844px) {
    font-size: 20px;
  }
`

const ChangeLogEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding: 10px;
`

const ChangeLogEntry = styled.div`
  margin-bottom: 10px;
`

const ChangeLogEntryMarkdownContainer = styled.div``

const ChangeLogItemHeader = styled.div``

const CloseButtonContainer = styled.div`
  margin: 10px;

  @media (min-width: 844px) {
    display: flex;
    justify-content: flex-start;
  }
`

const CloseButtonStyled = styled(LoadingButton)`
  @media (min-width: 844px) {
    width: 250px;
  }
`

const markedRenderer = new marked.Renderer()

markedRenderer.link = (href, title, text) => {
  return `<a class="marked-link" target="_blank" href="${href}" title="${text}">${text}</a>`
}

function replaceEmojiShortcodes(text: string): string {
  const emojiMap: Record<string, string> = {
    ':e-mail:': '📧',
    ':mag:': '🔍'
  }

  return text.replace(/:(\w+|-)+:/g, (match) => emojiMap[match] || match)
}

const originalText = markedRenderer.text.bind(markedRenderer)
markedRenderer.text = (text: string) => {
  return originalText(replaceEmojiShortcodes(text))
}

marked.setOptions({ renderer: markedRenderer, breaks: true })

const ChangeLogInfo = ({ changeLogs, handleClick }: ChangeLogInfoProps) => {
  return (
    <ChangeLogContainer>
      <ChangeLogHeader>
        <ChangeLogHeaderTitle variant="h3">The Ferrets have made some improvements! 🎉</ChangeLogHeaderTitle>
      </ChangeLogHeader>
      <ChangeLogEntryContainer>
        {changeLogs.map((changeLog, index) => {
          return (
            <div key={index}>
              <ChangeLogItemHeader>
                <Typography variant="h4">{dayjs(changeLog.releaseDate).format('Do MMMM YYYY')}</Typography>
              </ChangeLogItemHeader>
              <ul>
                {changeLog.items.map((item, index) => {
                  return (
                    <ChangeLogEntry key={index}>
                      <li>
                        <ChangeLogEntryMarkdownContainer
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(item.content)) }}
                        ></ChangeLogEntryMarkdownContainer>
                      </li>
                    </ChangeLogEntry>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </ChangeLogEntryContainer>
      <CloseButtonContainer>
        <CloseButtonStyled id="change-log-button" onClick={handleClick}>
          Close
        </CloseButtonStyled>
      </CloseButtonContainer>
    </ChangeLogContainer>
  )
}

export default ChangeLogInfo
