import { useCallback, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { TrackEventProperties } from '../types/TrackEventProperties'
import { isTrackingAllowed } from '../utils/analytics'

const useTrackEvent = (initialEventName?: string, props?: TrackEventProperties) => {
  const triggerEvent = useCallback((eventName: string, props?: TrackEventProperties) => {
    if (isTrackingAllowed()) {
      mixpanel.track(eventName, props)
    }
  }, [])

  useEffect(() => {
    if (initialEventName) {
      triggerEvent(initialEventName, props)
    }
  }, [initialEventName, props, triggerEvent])

  return triggerEvent
}

export default useTrackEvent
