import React, { useState } from 'react'
import styled from '@emotion/styled'
import MultiFilter from '../components/MultiFilter'
import TitleWithDivider from '../components/TitleWithDivider'
import CustomRequest from '../components/customRequest/CustomRequest'
import RequestsTable from '../components/userRequests/RequestsTable'
import { useMyRequests } from '../hooks/useMyRequests'
import useTrackEvent from '../hooks/useTrackEvent'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import MediaRequestStatuses from '../types/MediaRequestStatuses'
import { TrackEventType } from '../types/TrackEventType'
import { VirtualPage } from '../types/VirtualPage'

const StyledTitleWithDiver = styled(TitleWithDivider)`
  margin-top: 20px;
`

const MuiFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const RequestsPage = () => {
  useTrackVirtualPageView(VirtualPage.REQUESTS)
  const trackEvent = useTrackEvent()
  const [statusFilters, setStatusFilters] = useState<string[]>([MediaRequestStatuses.Requested])
  const { data, isLoading, getWithNewStatuses } = useMyRequests(statusFilters)

  const handleRequestSubmitted = async () => {
    await getWithNewStatuses(statusFilters, true)
  }

  const onFilterChanged = async (filters: string[]) => {
    trackEvent(TrackEventType.USER_REQUESTS.CLICK_FILTER_CHANGED, {
      filter: statusFilters.join(',')
    })
    setStatusFilters(filters)
    await getWithNewStatuses(filters)
  }

  return (
    <div>
      <div style={{ marginLeft: '5px', marginRight: '5px' }}>
        <CustomRequest onRequestSubmitted={handleRequestSubmitted} />
      </div>
      <StyledTitleWithDiver
        title="My Requests"
        titleCssProperties={{ paddingTop: '20px', marginBottom: '24px' }}
        displaySearchViewType={false}
      />
      <MuiFilterContainer>
        <MultiFilter
          data-cy={'request-status-filter'}
          items={{
            [MediaRequestStatuses.Requested]: MediaRequestStatuses.Requested,
            [MediaRequestStatuses.Approved]: MediaRequestStatuses.Approved,
            [MediaRequestStatuses.Available]: MediaRequestStatuses.Available,
            [MediaRequestStatuses.Rejected]: 'Unavailable'
          }}
          onFilterChange={onFilterChanged}
          isLoading={isLoading}
        />
      </MuiFilterContainer>
      <div style={{ padding: '10px 20px 20px 20px' }}>
        <RequestsTable isLoading={isLoading} mediaItems={data} />
      </div>
    </div>
  )
}

export default RequestsPage
