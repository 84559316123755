import React, { useEffect, useState } from 'react'
import { CookieConsent, getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent'
import { Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import AuthenticatedRoute from './auth/AuthenticatedRoute'
import StyledMaterialDesignContent from './data/StyledSnackbarContent'
import theme from './data/theme'
import AdminPage from './pages/AdminPage'
import ErrorPage from './pages/ErrorPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import NotFoundPage from './pages/NotFoundPage'
import ProfilePage from './pages/ProfilePage'
import RequestsPage from './pages/RequestsPage'
import SearchMediaResultsPage from './pages/SearchMediaResultsPage'
import routes from './routes'
import { initTracking, setTrackingCookie } from './utils/analytics'

function App() {
  const [trackingAllowed, setTrackingAllowed] = useState<boolean>(getCookieConsentValue() === 'tracking')

  const handleAcceptAll = (): void => {
    setTrackingAllowed(true)
    setTrackingCookie('tracking')
  }

  const handleAcceptEssentialOnly = (): void => {
    setTrackingAllowed(false)
    resetCookieConsentValue()
    setTrackingCookie('essential')
  }

  useEffect(() => {
    if (trackingAllowed) {
      initTracking()
    }
  }, [trackingAllowed])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent
        }}
        autoHideDuration={3000}
      >
        <CssBaseline />
        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          enableDeclineButton
          declineButtonText="Accept Essential Only"
          cookieName="CookieConsent"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          declineButtonStyle={{ color: '#fff', backgroundColor: '#6c757d', fontSize: '13px' }}
          onAccept={handleAcceptAll}
          onDecline={handleAcceptEssentialOnly}
          expires={365}
        >
          This website uses cookies to enhance the user experience. You can choose to accept all cookies or only
          essential ones.
        </CookieConsent>
        <Routes>
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.error} element={<ErrorPage />} />
          <Route element={<AuthenticatedRoute component={HomePage} targetRoute={routes.root} />} path={routes.root} />
          <Route element={<AuthenticatedRoute component={HomePage} />} path={routes.home} />\
          <Route
            element={<AuthenticatedRoute component={SearchMediaResultsPage} />}
            path={routes.searchResults.route}
          />
          <Route element={<AuthenticatedRoute component={RequestsPage} />} path={routes.requests} />\
          <Route element={<AuthenticatedRoute component={ProfilePage} />} path={routes.profile} />\
          <Route element={<AuthenticatedRoute component={AdminPage} />} path={routes.admin} />
          <Route element={<AuthenticatedRoute component={LogoutPage} />} path={routes.logout} />\
          <Route path={routes.wildcard} element={<NotFoundPage />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
