import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import useTrackEvent from '../../../hooks/useTrackEvent'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import RequestButtonType from '../../../types/RequestButtonType'
import { TrackEventType } from '../../../types/TrackEventType'
import { getBackdropUrl } from '../../../utils/images'
import RequestStatusContainer from '../../requestStatus/RequestStatusContainer'
import SearchListViewItemContainer from './SearchListViewItemContainer'
import SearchListViewItemMediaIcon from './SearchListViewItemMediaIcon'
import SearchListViewItemPoster from './SearchListViewItemPoster'
import SearchListViewItemTitle from './SearchListViewItemTitle'

interface SearchResultsListViewProps {
  mediaItems: MediaItemDetails[] | undefined
  isLoading: boolean
}

const rowHeight = '199px'

const skeletonItems = 11

const SearchResultsListView = ({ mediaItems, isLoading }: SearchResultsListViewProps) => {
  const trackEvent = useTrackEvent()

  if (isLoading) {
    return (
      <Grid container flexDirection="column" wrap="nowrap">
        {Array.from(Array(skeletonItems)).map((item, index) => (
          <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
            <Skeleton variant="rectangular" width="100%" height={rowHeight} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton variant="text" width="60%" />
            </Box>
          </Box>
        ))}
      </Grid>
    )
  }

  const handleTitleClicked = (mediaItem: MediaItemDetails) => {
    trackEvent(TrackEventType.SEARCH_RESULTS.CLICK_TITLE, {
      mediaItemId: mediaItem.id,
      mediaItemTitle: mediaItem.title
    })
  }

  const handlePosterClicked = (mediaItem: MediaItemDetails) => {
    trackEvent(TrackEventType.SEARCH_RESULTS.CLICK_POSTER, {
      mediaItemId: mediaItem.id,
      mediaItemTitle: mediaItem.title
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '20px'
      }}
    >
      {mediaItems &&
        mediaItems.map((mediaItem) => (
          <div
            key={mediaItem.id}
            style={{
              width: '100%'
            }}
          >
            <SearchListViewItemContainer
              backdropHeight={rowHeight}
              backdropPath={getBackdropUrl(mediaItem.mediaType, mediaItem.backdropPath)}
            >
              <Box sx={{ flexGrow: 1, width: '100%' }}>
                <Grid container direction="row" style={{ height: rowHeight }}>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                    onClick={() => handlePosterClicked(mediaItem)}
                  >
                    <SearchListViewItemPoster mediaItem={mediaItem.mediaType} posterPath={mediaItem.posterPath} />
                  </Grid>
                  <Grid item xs={9} style={{ height: '100%' }}>
                    <Grid container direction="column" style={{ height: rowHeight }}>
                      <Grid
                        item
                        xs={9}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start'
                        }}
                        onClick={() => handleTitleClicked(mediaItem)}
                      >
                        <SearchListViewItemTitle
                          title={mediaItem.title}
                          year={mediaItem.year}
                          overview={mediaItem.overview}
                        ></SearchListViewItemTitle>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <RequestStatusContainer
                          mediaItem={mediaItem}
                          buttonType={RequestButtonType.CHIP}
                        ></RequestStatusContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} style={{ height: '100%' }}>
                    <Grid container style={{ height: '100%' }}>
                      <Grid
                        item
                        xs
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <SearchListViewItemMediaIcon mediaType={mediaItem.mediaType} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </SearchListViewItemContainer>
          </div>
        ))}
    </div>
  )
}

export default SearchResultsListView
