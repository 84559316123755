import React from 'react'
import WorkerFerret from '../components/WorkerFerret'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import { VirtualPage } from '../types/VirtualPage'

const ErrorPage = () => {
  useTrackVirtualPageView(VirtualPage.ERROR)
  return (
    <div>
      <WorkerFerret />
    </div>
  )
}

export default ErrorPage
