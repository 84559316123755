import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import routes from '../../routes'
import { UserProfile } from '../../types/UserProfile'
import UserProfileAvatar from './UserProfileAvatar'

interface UserProfileSettingsProps {
  userProfile: UserProfile
}

const StyledBox = styled(Box)`
  @media (max-width: 960px) {
    gap: 1.5rem;
  }
  gap: 2rem;
  margin-right: 5px;
`

interface SettingRoutes {
  title: string
  route: string
}

const settings: SettingRoutes[] = [
  {
    title: 'Requests',
    route: routes.requests
  },
  {
    title: 'Profile',
    route: routes.profile
  }
]

const adminSettings: SettingRoutes[] = [
  {
    title: 'Admin',
    route: routes.admin
  }
]

const logoutSetting: SettingRoutes = {
  title: 'Logout',
  route: routes.logout
}

const UserProfileSettings = ({ userProfile }: UserProfileSettingsProps) => {
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const [currentSettings, setCurrentSettings] = useState<SettingRoutes[]>([])

  useEffect(() => {
    const startSettings = [...settings]
    if (userProfile.isAdmin) {
      startSettings.push(...adminSettings)
    }
    startSettings.push(logoutSetting)
    setCurrentSettings(startSettings)
  }, [])

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (navPath: any) => {
    setAnchorElUser(null)
    if (typeof navPath === 'string') {
      navigate(navPath)
    }
  }

  return (
    <StyledBox sx={{ display: 'flex', alignItems: 'center', flexGrow: 0, marginRight: '20px' }}>
      <UserProfileAvatar avatarUrl={userProfile.avatarUrl} onClick={handleOpenUserMenu} />
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {currentSettings.map((setting) => (
          <MenuItem key={setting.title} onClick={() => handleCloseUserMenu(setting.route)}>
            <Typography textAlign="center">{setting.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </StyledBox>
  )
}

export default UserProfileSettings
