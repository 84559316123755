import React from 'react'
import FerretMaze from '../components/FerretMaze'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import { VirtualPage } from '../types/VirtualPage'

const NotFoundPage = () => {
  useTrackVirtualPageView(VirtualPage.NOT_FOUND)
  return <FerretMaze />
}

export default NotFoundPage
