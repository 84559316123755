import { getCookieConsentValue } from 'react-cookie-consent'
import Hotjar from '@hotjar/browser'
import mixpanel from 'mixpanel-browser'

const getMixpanelToken = (): string => {
  const hostname = window.location.hostname

  if (hostname.toLowerCase() === 'ferretflix.com' || hostname.toLowerCase() === 'www.ferretflix.com') {
    return '8cf2bcb416957c0c98b116ba1e503888'
  } else {
    return '79c12d1a154b0f9309d910c312e0249e'
  }
}

const hotJarConfig = {
  siteId: 3602357,
  version: 6
}

export const initTracking = () => {
  if (isTrackingAllowed()) {
    Hotjar.init(hotJarConfig.siteId, hotJarConfig.version)
    mixpanel.init(getMixpanelToken())
  }
}

export const isTrackingAllowed = () => {
  return getCookieConsentValue() === 'tracking'
}

export const setTrackingCookie = (value: string) => {
  const sameSite = window.location.hostname !== 'localhost' ? 'None' : 'Lax'
  const secureFlag = window.location.hostname !== 'localhost' ? ' Secure' : ''

  document.cookie = `CookieConsent=${value}; path=/; max-age=${60 * 60 * 24 * 365}; SameSite=${sameSite};${secureFlag}`
}
