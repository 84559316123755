import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import TitleWithDivider from '../components/TitleWithDivider'
import SearchMedia from '../components/homePageSearch/SearchMedia'
import NoResultsFound from '../components/searchResult/NoResultsFound'
import SearchResultsGridView from '../components/searchResult/gridView/SearchResultsGridView'
import SearchResultsListView from '../components/searchResult/listView/SearchResultsListView'
import { useFactOfTheDay } from '../hooks/useFactOfTheDay'
import { useSearchMediaQuery } from '../hooks/useSearchMedia'
import useTrackEvent from '../hooks/useTrackEvent'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import SearchResultsViewTypes from '../types/SearchResultsViewTypes'
import { TrackEventType } from '../types/TrackEventType'
import { VirtualPage } from '../types/VirtualPage'

const StyledTitleWithDiver = styled(TitleWithDivider)`
  margin-top: 20px;
`

const SearchMediaResultsPage = () => {
  useTrackVirtualPageView(VirtualPage.SEARCH_RESULTS)
  const trackEvent = useTrackEvent()
  const { searchTerm } = useParams()
  const { data: mediaItems, isLoading: isSearchLoading } = useSearchMediaQuery({ searchTerm })
  const { data: factOfTheDay, isLoading: isFactOfTheDayLoading } = useFactOfTheDay()
  const [selectedViewType, setSelectedViewType] = React.useState(SearchResultsViewTypes.List)

  const isLoading = isFactOfTheDayLoading || isSearchLoading

  const handleToggleButtonGroupChanged = (event: React.MouseEvent<HTMLElement>, newSelectedItem: string) => {
    trackEvent(TrackEventType.SEARCH_RESULTS.CLICK_VIEW_CHANGED, {
      viewTypeSelected: newSelectedItem
    })
    setSelectedViewType(newSelectedItem as SearchResultsViewTypes)
  }

  return (
    <div>
      <SearchMedia factOfTheDay={factOfTheDay}></SearchMedia>
      <StyledTitleWithDiver
        title="Search Results"
        titleCssProperties={{ paddingTop: '20px', marginBottom: '24px' }}
        displaySearchViewType={true}
        onSearchViewTypeButtonGroupChange={handleToggleButtonGroupChanged}
      />
      {!isLoading && mediaItems?.length === 0 ? (
        <NoResultsFound />
      ) : selectedViewType === SearchResultsViewTypes.List ? (
        <SearchResultsListView mediaItems={mediaItems} isLoading={isLoading} />
      ) : (
        <SearchResultsGridView mediaItems={mediaItems} isLoading={isLoading} />
      )}
    </div>
  )
}

export default SearchMediaResultsPage
