export const VirtualPage = {
  HOME: 'page_home',
  SEARCH_RESULTS: 'page_searchResults',
  REQUESTS: 'page_requests',
  PROFILE: 'page_profile',
  ADMIN: 'page_admin',
  LOGOUT: 'page_logout',
  ERROR: 'page_error',
  NOT_FOUND: 'page_notFound'
}
