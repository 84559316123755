import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '../../data/theme'
import { useUpcomingMovies } from '../../hooks/useUpcomingMovies'
import TitleWithDivider from '../TitleWithDivider'
import YouTubeMediaItemSlider from '../youtubeSlider/YouTubeMediaItemSlider'

const UpcomingMovies = () => {
  const desktopClients = useMediaQuery(theme.breakpoints.up('md'))
  const { data: upcomingMovies, isLoading: isUpcomingMoviesLoading } = useUpcomingMovies()

  const skeletonItems: number = desktopClients ? 3 : 1

  return (
    <>
      <TitleWithDivider title="Upcoming Movies"></TitleWithDivider>
      {isUpcomingMoviesLoading ? (
        <Grid container wrap="nowrap" sx={{ marginLeft: '10px' }}>
          {Array.from(Array(skeletonItems)).map((item, index) => (
            <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
              <Skeleton variant="rectangular" width={370} height={240} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton variant="text" width="60%" />
              </Box>
            </Box>
          ))}
        </Grid>
      ) : (
        <YouTubeMediaItemSlider movies={upcomingMovies!.items}></YouTubeMediaItemSlider>
      )}
    </>
  )
}

export default UpcomingMovies
