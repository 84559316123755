import React, { useState } from 'react'
import styled from '@emotion/styled'
import Close from '@mui/icons-material/Close'
import Settings from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import Chip, { ChipProps } from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useTrackEvent from '../../hooks/useTrackEvent'
import MediaTypes from '../../types/MediaTypes'
import { TrackEventType } from '../../types/TrackEventType'
import CustomRequestForm from './CustomRequestForm'

interface StyledRequestChipProps extends ChipProps {
  borderColor: string
}

const StyledRequestChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'borderColor'
})<StyledRequestChipProps>`
  border: 1px solid ${({ borderColor }) => borderColor};
  margin-bottom: 5px;
  margin-left: 5px;
`

const StyledModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 485px;
  border: 2px solid #000;
  boxshadow: 24;
  padding: 10px;

  background-color: #d9d9d9;
  color: #333333;
  border-radius: 20px;
  border: 3px black #333333;

  //iPhone 12 XR (Landscape)
  @media (max-width: 896px) {
    overflow: scroll;
    width: 600px;
    height: 455px;
    height: 80vh;
  }

  //iPhone 12 Pro (Landscape)
  @media (max-width: 844px) {
    overflow: scroll;
    width: 600px;
    height: 80vh;
  }

  //iPad Air (Landscape)
  @media (max-width: 820px) {
    width: 600px;
    height: 500px;
  }

  //iPhone SE (Landscape)
  @media (max-width: 667px) {
    overflow: scroll;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 85vh;
  }

  //iPhone 12 XR (Portrait)
  @media (max-width: 414px) {
    overflow: scroll;
    top: 45%;
    left: 50%;
    width: 300px;
    height: 475px;
  }

  //iPhone 12 Pro (Portrait)
  @media (max-width: 390px) {
    top: 45%;
    left: 50%;
    width: 300px;
    height: 475px;
  }

  //iPhone SE (Portrait)
  @media (max-width: 375px) {
    top: 45%;
    left: 50%;
    width: 300px;
    height: 480px;
  }
`

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

interface CustomRequestButtonProps {
  onRequestSubmitted: () => void
}

const CustomRequestButton = ({ onRequestSubmitted }: CustomRequestButtonProps) => {
  const trackEvent = useTrackEvent()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const handleModalOpen = () => {
    trackEvent(TrackEventType.CUSTOM_REQUEST.CLICK_OPEN)
    setOpen(true)
  }
  const handleModalClose = () => {
    trackEvent(TrackEventType.CUSTOM_REQUEST.CLICK_CLOSE)
    setOpen(false)
  }

  const handleSubmitSucceeded = (mediaType: MediaTypes) => {
    trackEvent(TrackEventType.CUSTOM_REQUEST.CLICK_SUBMIT, {
      mediaType: mediaType
    })
    setOpen(false)
    onRequestSubmitted()
  }

  return (
    <>
      <StyledRequestChip
        borderColor={theme.palette.common.black}
        label="Custom Request"
        variant="filled"
        icon={<Settings />}
        onClick={handleModalOpen}
      />
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModal id="custom-request-modal">
          <ModalTitle>
            <Typography id="modal-modal-title" variant="h3" sx={{ color: theme.palette.common.black }}>
              Custom Request
            </Typography>
            <IconButton sx={{ backgroundColor: 'transparent' }} onClick={handleModalClose}>
              <Close />
            </IconButton>
          </ModalTitle>
          <CustomRequestForm onSubmitSucceeded={handleSubmitSucceeded} />
        </StyledModal>
      </Modal>
    </>
  )
}

export default CustomRequestButton
