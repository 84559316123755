import React, { useState } from 'react'
import styled from '@emotion/styled'
import Backdrop from '@mui/material/Backdrop'
import ChangeLogInfo from '../components/changeLog/ChangeLogInfo'
import SearchMedia from '../components/homePageSearch/SearchMedia'
import RecentlyDownloaded from '../components/recentlyDownloaded/RecentlyDownloaded'
import Recommendations from '../components/recommendations/Recommendations'
import UpcomingMovies from '../components/upcomingMovies/UpcomingMovies'
import { useChangeLog } from '../hooks/useChangeLog'
import { useFactOfTheDay } from '../hooks/useFactOfTheDay'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import { VirtualPage } from '../types/VirtualPage'

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

const HomePage = () => {
  useTrackVirtualPageView(VirtualPage.HOME)
  const [canDisplayChangelog, setCanDisplayChangelog] = useState(true)
  const { data: factOfTheDay } = useFactOfTheDay()
  const { data: changeLog } = useChangeLog()

  const handleChangeLogClose = () => {
    setCanDisplayChangelog(false)
  }

  return (
    <>
      <HomePageContainer>
        <SearchMedia factOfTheDay={factOfTheDay}></SearchMedia>
        <Recommendations />
        <UpcomingMovies />
        <RecentlyDownloaded />
      </HomePageContainer>
      {changeLog && changeLog?.length > 0 && canDisplayChangelog && (
        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <ChangeLogInfo changeLogs={changeLog} handleClick={handleChangeLogClose} />
        </Backdrop>
      )}
    </>
  )
}

export default HomePage
