import React from 'react'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import useTrackEvent from '../../hooks/useTrackEvent'
import routes from '../../routes'
import { TrackEventType } from '../../types/TrackEventType'
import { getImageUrl } from '../../utils/images'

const StyledLogo = styled.img`
  height: 53px;
  width: 90px;
  margin-top: 5px;
`

const NavBarLogo = () => {
  const trackEvent = useTrackEvent()

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    trackEvent(TrackEventType.NAV_BAR.CLICK_LOGO)

    setTimeout(() => {
      window.location.href = routes.home
    }, 300)
  }

  return (
    <Tooltip title="Home" placement="right" arrow>
      <a href={routes.home} onClick={handleLogoClick}>
        <StyledLogo src={getImageUrl('Logo-min.png')} alt="FerretFlix logo" />
      </a>
    </Tooltip>
  )
}

export default NavBarLogo
