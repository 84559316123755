import React, { useState } from 'react'
import styled from '@emotion/styled'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import content from '../data/content'
import useTrackEvent from '../hooks/useTrackEvent'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import { useUpdateUserNotification } from '../hooks/useUpdateUserNotification'
import { TrackEventType } from '../types/TrackEventType'
import { UserProfile } from '../types/UserProfile'
import { VirtualPage } from '../types/VirtualPage'

interface ProfilePageProps {
  userProfile?: UserProfile
}

const StyledTitle = styled(Typography)`
  margin-bottom: 10px;
`

const StyledProfileImage = styled.img`
  border-radius: 50%;
  height: 90px;
  width: 90px;
`

const StyledDivider = styled(Divider)`
  margin-top: 20px;
`

const StyledPlexProfileLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
`

const NotificationUpdatingContainer = styled.div`
  padding-left: 10px;
  margin-right: 12px;
  padding-top: 6px;
`

const componentContent = content.pages.profile

interface UserNotification {
  notificationType: string
  isIncluded: boolean
}

const ProfilePage = ({ userProfile }: ProfilePageProps) => {
  useTrackVirtualPageView(VirtualPage.PROFILE)
  const trackEvent = useTrackEvent()
  const [isLoading, setIsLoading] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const initialNotifications: UserNotification[] =
    userProfile?.notifications.map((notification) => {
      return {
        notificationType: notification.notificationType,
        isIncluded: notification.isIncluded
      }
    }) || []

  const [notifications, setNotifications] = useState<UserNotification[]>(initialNotifications)

  const handleSendMediaRequestSuccess = (notificationUpdated: UserNotification) => {
    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.notificationType === notificationUpdated.notificationType
            ? { ...notification, isIncluded: notificationUpdated.isIncluded }
            : notification
        )
      )
      setIsLoading('')
      enqueueSnackbar(
        `${notificationUpdated.notificationType} notification ${
          notificationUpdated.isIncluded ? 'enabled' : 'disabled'
        }`,
        { variant: 'success' }
      )
    }, 1000)
  }

  const handleSendMediaRequestError = (message: string) => {
    setTimeout(() => {
      enqueueSnackbar(message, { variant: 'error' })
      setIsLoading('')
    }, 1000)
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    trackEvent(TrackEventType.PROFILE.CLICK_PLEX_PROFILE_LINK)

    setTimeout(() => {
      window.open(componentContent.plexProfile.link, '_blank', 'noopener,noreferrer')
    }, 300)
  }

  const { mutate } = useUpdateUserNotification({
    onSuccess: handleSendMediaRequestSuccess,
    onError: handleSendMediaRequestError
  })

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const changedNotificationType = event.target.value
    setIsLoading(changedNotificationType)
    trackEvent(TrackEventType.PROFILE.CLICK_NOTIFICATION_CHANGED, {
      notificationType: changedNotificationType,
      notificationState: checked.toString()
    })
    mutate({
      notificationType: changedNotificationType,
      isIncluded: checked
    })
  }

  return (
    <Grid container rowSpacing={15} justifyContent="space-between" alignItems="center" sx={{ padding: '15px' }}>
      <Grid item xs={6}>
        <Typography variant="h1">{componentContent.title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <StyledPlexProfileLink
          variant="h4"
          href={componentContent.plexProfile.link}
          rel="noreferrer noopener"
          target="_blank"
          onClick={handleLinkClick}
        >
          {componentContent.plexProfile.title}
        </StyledPlexProfileLink>
      </Grid>
      <Grid item xs={12}>
        <StyledProfileImage src={userProfile?.avatarUrl} alt={componentContent.image.imageAlt}></StyledProfileImage>
        <StyledDivider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <StyledTitle variant="h3">{componentContent.username.title}</StyledTitle>
        <Typography variant="body1">{userProfile?.username || componentContent.defaultValue}</Typography>
        <StyledDivider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <StyledTitle variant="h3">{componentContent.email.title}</StyledTitle>
        <Typography variant="body1">{userProfile?.email || componentContent.defaultValue}</Typography>
        <StyledDivider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <StyledTitle variant="h3">{componentContent.notifications.title}</StyledTitle>
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          {componentContent.notifications.description}
        </Typography>
        {notifications.map((notificationType) => {
          return (
            <div key={notificationType.notificationType}>
              {
                <FormControlLabel
                  control={
                    isLoading === notificationType.notificationType ? (
                      <NotificationUpdatingContainer>
                        <CircularProgress size={20} />
                      </NotificationUpdatingContainer>
                    ) : (
                      <Checkbox
                        checked={
                          notifications.find(
                            (notification) => notification.notificationType === notificationType.notificationType
                          )?.isIncluded
                        }
                        onChange={handleCheckboxChange}
                        value={notificationType.notificationType}
                      />
                    )
                  }
                  label={notificationType.notificationType}
                />
              }
            </div>
          )
        })}
        <StyledDivider variant="middle" />
      </Grid>
    </Grid>
  )
}

export default ProfilePage
