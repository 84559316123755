import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import LoadingFerret from '../components/loading/LoadingFerret'
import AppNavBar from '../components/navBar/AppNavBar'
import { useUserProfile } from '../hooks/useUserProfile'
import routes from '../routes'
import { isTrackingAllowed } from '../utils/analytics'

interface AuthenticatedRouteProps {
  component: React.ElementType
  targetRoute?: string
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component: Component, targetRoute }) => {
  const retries = targetRoute === routes.root ? 0 : 3
  const { data: userProfile, isLoading, retriesRemaining } = useUserProfile(retries)
  const isAuthenticatedUser = userProfile?.userId !== undefined
  const [isPageLoading, setIsPageLoading] = useState(isLoading)

  useEffect(() => {
    if (isAuthenticatedUser && isTrackingAllowed()) {
      mixpanel.identify(userProfile.userId)
      mixpanel.people.set({
        $email: userProfile.email,
        $name: userProfile.username,
        isAdmin: userProfile.isAdmin
      })
    }
  }, [isAuthenticatedUser, userProfile])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsPageLoading(false)
      }, 1000)
    }
  }, [isLoading])

  if (isPageLoading) {
    return <LoadingFerret />
  }

  if (isAuthenticatedUser) {
    return (
      <AppNavBar userProfile={userProfile}>
        <Component userProfile={userProfile} />
      </AppNavBar>
    )
  } else {
    if (retriesRemaining > 0) {
      return <p>Loading</p>
    } else {
      return <Navigate to={routes.login} />
    }
  }
}

export default AuthenticatedRoute
