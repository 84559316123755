import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { useSnackbar } from 'notistack'
import content from '../../data/content'
import { useCustomSendMediaRequest } from '../../hooks/useSendCustomMediaRequest'
import { CustomRequestFormData } from '../../types/CustomRequestFormData'
import MediaTypes from '../../types/MediaTypes'
import TextInput from '../input/TextInput'
import LoadingButton from '../loading/LoadingButton'
import MediaTypeSelector from './MediaTypeSelector'

const componentContent = content.pages.requests

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const currentYear = new Date().getFullYear()
const maxYear = currentYear + 10

interface CustomRequestFormProps {
  onSubmitSucceeded: (mediaType: MediaTypes) => void
}

const CustomRequestForm = ({ onSubmitSucceeded }: CustomRequestFormProps) => {
  const [title, setTitle] = useState<string>('')
  const [year, setYear] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [mediaType, setMediaType] = useState<MediaTypes>(MediaTypes.Movie)
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSendMediaRequestSuccess = () => {
    setTimeout(() => {
      setIsLoading(false)
      enqueueSnackbar('Request sent successfully', { variant: 'success' })
      onSubmitSucceeded(mediaType)
    }, 1000)
  }

  const handleSendMediaRequestError = (message: string) => {
    setTimeout(() => {
      enqueueSnackbar(message, { variant: 'error' })
      setIsLoading(false)
    }, 1000)
  }

  const { mutate } = useCustomSendMediaRequest({
    onSuccess: handleSendMediaRequestSuccess,
    onError: handleSendMediaRequestError
  })

  const onSubmit = () => {
    let parsedYear: number | undefined = parseInt(year)
    if (isNaN(parsedYear)) {
      parsedYear = undefined
    }

    setIsLoading(true)
    mutate({
      title: title,
      year: parsedYear,
      mediaType: mediaType,
      comment: comment
    })
  }

  const handleMediaTypeSelectionChanged = (event: React.MouseEvent<HTMLElement>, mediaType: MediaTypes) => {
    setMediaType(mediaType)
  }

  const form = useForm<CustomRequestFormData>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      year: '',
      comment: ''
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          register={register}
          name="title"
          type="text"
          showLabel={false}
          placeholder={componentContent.fields.title.placeholder}
          error={errors.title}
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          validation={{
            required: componentContent.fields.title.erorrs.required,
            minLength: {
              value: 1,
              message: componentContent.fields.title.erorrs.minLength
            },
            maxLength: {
              value: 150,
              message: componentContent.fields.title.erorrs.maxLength
            }
          }}
        ></TextInput>
        <TextInput
          register={register}
          name="year"
          type="number"
          showLabel={false}
          placeholder={componentContent.fields.year.placeholder}
          error={errors.year}
          value={year}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setYear(event.target.value)}
          validation={{
            min: {
              value: 1900,
              message: 'Year cannot be before 1900'
            },
            max: {
              value: maxYear,
              message: `Year cannot be more than ${maxYear}`
            }
          }}
          sx={{
            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            },
            '& input[type=number]': {
              '-moz-appearance': 'textfield'
            }
          }}
        ></TextInput>
        <TextInput
          register={register}
          name="comment"
          type="text"
          showLabel={false}
          placeholder={componentContent.fields.comment.placeholder}
          error={errors.comment}
          value={comment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
          validation={{
            maxLength: {
              value: 2048,
              message: componentContent.fields.title.erorrs.maxLength
            }
          }}
        ></TextInput>
        <div style={{ marginTop: '15px' }}>
          <MediaTypeSelector
            onToggleChanged={handleMediaTypeSelectionChanged}
            sx={{ marginLeft: '0px', marginBottom: '15px' }}
          />
        </div>
        <LoadingButton id="submit-sign-in-button" isLoading={isLoading}>
          {componentContent.submitButtonTitle}
        </LoadingButton>
      </form>
    </FormContainer>
  )
}

export default CustomRequestForm
