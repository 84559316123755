import React, { useCallback } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import debounce from '@mui/material/utils/debounce'
import MediaRequestStatuses from '../types/MediaRequestStatuses'
import Spinner from './loading/Spinner'

interface ItemMap {
  [key: string]: string
}

interface MultiFilterProps {
  items: ItemMap
  onFilterChange: (filter: string[]) => void
  isLoading?: boolean
  selectProps?: ModifiableSelectProps
}

interface ModifiableSelectProps {
  id?: string
  'data-cy'?: string
}

const allItemsFilter = 'All'

const MultiFilter = ({ items, onFilterChange, isLoading, selectProps }: MultiFilterProps) => {
  const [currentItems, setCurrentItems] = React.useState<string[]>([MediaRequestStatuses.Requested])

  const debouncedOnChange = useCallback(
    debounce((newItems: string[]) => {
      onFilterChange(newItems)
    }, 1000),
    [onFilterChange]
  )

  const handleChange = (event: SelectChangeEvent<typeof currentItems>) => {
    const {
      target: { value }
    } = event
    const newItems = typeof value === 'string' ? value.split(',') : value
    if (newItems.length === 0) {
      newItems.push(allItemsFilter)
    } else if (newItems.includes(allItemsFilter)) {
      newItems.splice(newItems.indexOf(allItemsFilter), 1)
    }
    setCurrentItems(newItems)
    debouncedOnChange(newItems)
  }

  function renderValue(selected: string[]) {
    return selected.length === 0 ? [allItemsFilter] : selected.join(', ')
  }

  if (isLoading === true) {
    return <Spinner />
  }

  return (
    <FormControl sx={{ width: 300 }}>
      <Select multiple value={currentItems} onChange={handleChange} renderValue={renderValue} {...selectProps}>
        {Object.entries(items).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={currentItems.includes(key)} />
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiFilter
