import { useCallback, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { PageViewProperties } from '../types/PageViewProperties'
import { isTrackingAllowed } from '../utils/analytics'

const useTrackVirtualPageView = (page?: string, properties?: PageViewProperties) => {
  const trackPage = useCallback((page: string, properties?: PageViewProperties) => {
    if (isTrackingAllowed()) {
      mixpanel.track(page, properties)
    }
  }, [])

  useEffect(() => {
    if (page) {
      trackPage(page, properties)
    }
  }, [page, properties, trackPage])

  return trackPage
}

export default useTrackVirtualPageView
